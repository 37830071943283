import React, { useEffect } from "react";
import "./AdminSidebar.css";
import {
  FaBattleNet,
  FaCodePullRequest,
  FaUsersGear,
  FaCircleDollarToSlot,
} from "react-icons/fa6";
import logo from "../../Assets/Logo.PNG";
import { useNavigate, Link } from "react-router-dom";
import { useHireContext } from "../../App.js";

const AdminSidebar = ({ isActive, setIsActive }) => {
  const { setIsLoggedIn, name, email } = useHireContext();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    setIsLoggedIn(false);
    navigate("/login");
  };
  return (
    <>
      <div
        className={`side-navbar  ${
          isActive ? "active-nav" : ""
        } d-flex justify-content-between flex-wrap flex-column`}
        id="sidebar"
      >
        <ul className="nav flex-column text-white w-100">
          <div className="logo-div">
            <img src={logo} alt="" style={{ width: "170px", height: "50px" }} />
          </div>
          <Link to="/" className="nav-link">
            <FaCodePullRequest className="sidebar-icon" />
            <span className="mx-2">Site Orders</span>
          </Link>
          <Link to="/portalOrders" className="nav-link">
            <FaCodePullRequest className="sidebar-icon" />
            <span className="mx-2">Portal Orders</span>
          </Link>
          <Link to="/siteTransactions" className="nav-link">
            <FaCodePullRequest className="sidebar-icon" />
            <span className="mx-2">Site Transactions</span>
          </Link>
          <Link to="/portalTransactions" className="nav-link">
            <FaCodePullRequest className="sidebar-icon" />
            <span className="mx-2">Portal Transactions</span>
          </Link>
          <Link to="/portalUsers" className="nav-link">
            <FaCircleDollarToSlot className="sidebar-icon" />
            <span className="mx-2">Portal Users</span>
          </Link>
          <Link to="/users" className="nav-link">
            <FaCircleDollarToSlot className="sidebar-icon" />
            <span className="mx-2">Site Users</span>
          </Link>
          <Link to="/bitjem" className="nav-link">
            <FaCircleDollarToSlot className="sidebar-icon" />
            <span className="mx-2">BitJem Account</span>
          </Link>
          <Link to="/paystack" className="nav-link">
            <FaCircleDollarToSlot className="sidebar-icon" />
            <span className="mx-2">Paystack</span>
          </Link>
          <Link to="/discounts" className="nav-link">
            <FaCircleDollarToSlot className="sidebar-icon" />
            <span className="mx-2">Discounts</span>
          </Link>
          <Link to="/giftcards" className="nav-link">
            <FaCircleDollarToSlot className="sidebar-icon" />
            <span className="mx-2">Gift Cards</span>
          </Link>
          <Link to="/portalPricing" className="nav-link">
            <FaCircleDollarToSlot className="sidebar-icon" />
            <span className="mx-2">Portal Pricing</span>
          </Link>
          <Link to="/ozchestPricing" className="nav-link">
            <FaCircleDollarToSlot className="sidebar-icon" />
            <span className="mx-2">Ozchest Pricing</span>
          </Link>
          <Link to="/createUser" className="nav-link">
            <FaCircleDollarToSlot className="sidebar-icon" />
            <span className="mx-2">Create User</span>
          </Link>
          <Link to="/adminSettings" className="nav-link">
            <FaCircleDollarToSlot className="sidebar-icon" />
            <span className="mx-2">Settings</span>
          </Link>
        </ul>
        <div>
          <div className="profile-bar">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="grey"
                width="50px"
                height="50px"
                viewBox="0 0 512 512"
              >
                <path
                  d="M256 73.825a182.175 182.175 0 1 0 182.18 182.18A182.177 182.177 0 0 0 256 73.825zm0 71.833a55.05 55.05 0 1 1-55.054 55.046A55.046 55.046 0 0 1 256 145.658zm.52 208.723h-80.852c0-54.255 29.522-73.573 48.885-90.906a65.68 65.68 0 0 0 62.885 0c19.363 17.333 48.885 36.651 48.885 90.906z"
                  data-name="Profile"
                />
              </svg>
            </div>
            <div className="profile-info">
              <span>{name}</span>
              <span style={{ fontSize: "14px" }}>{email}</span>
            </div>
          </div>
          <Link
            to="/"
            onClick={handleLogout}
            className="btn btn-secondary logout-btn"
          >
            Logout
          </Link>
        </div>
      </div>
    </>
  );
};

export default AdminSidebar;
