import React, { useState, useEffect } from "react";
import "./GiftCard.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GiftCard = () => {
  const [giftCards, setGiftCards] = useState([]);
  const [filteredGiftCards, setFilteredGiftCards] = useState([]);
  const [giftCardCode, setGiftCardCode] = useState("");
  const [usdValue, setUsdValue] = useState("");
  const [amountFilter, setAmountFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  useEffect(() => {
    axios
      .post("https://Business.ozchest.com/get-gift-cards", {
        isAdmin: sessionStorage.getItem("isAdmin"),
      })
      .then((res) => {
        if (res) {
          setGiftCards(res.data);
          setFilteredGiftCards(res.data); // Set the initial filtered list
        }
      });
  }, []);

  const handleAddGiftCard = () => {
    if (giftCardCode.trim() !== "" && usdValue.trim() !== "") {
      const data = {
        isAdmin: sessionStorage.getItem("isAdmin"),
        code: giftCardCode,
        amount: usdValue,
      };
      axios
        .post("https://Business.ozchest.com/gift-cards", data)
        .then((res) => {
          if (res) {
            setGiftCards([...giftCards, res.data.giftCard]);
            setFilteredGiftCards([...giftCards, res.data.giftCard]); // Update filtered list
            setGiftCardCode("");
            setUsdValue("");
            toast.success("Successfully Added New Gift Card");
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            toast.error("Gift card With Same Code Already Exists");
          } else {
            toast.error("Something went wrong!");
          }
        });
    } else {
      toast.error("Please enter all fields of the new gift card");
    }
  };

  const handleRemoveGiftCard = (giftCardToRemove) => {
    const data = {
      isAdmin: sessionStorage.getItem("isAdmin"),
      id: giftCardToRemove,
    };
    axios.post("https://Bu/delete-gift-cards", data).then((res) => {
      if (res) {
        const updatedGiftCards = giftCards.filter(
          (giftCard) => giftCard._id !== giftCardToRemove
        );
        setGiftCards(updatedGiftCards);
        setFilteredGiftCards(updatedGiftCards); // Update filtered list
        toast.success("Successfully Removed Gift Card Code");
      } else {
        toast.error("Something went wrong!");
      }
    });
  };

  // Filter the gift cards based on selected amount and status filters
  useEffect(() => {
    const filtered = giftCards.filter((giftCard) => {
      return (
        (amountFilter === "" || giftCard.amount === parseInt(amountFilter)) &&
        (statusFilter === "" || giftCard.status === statusFilter)
      );
    });
    setFilteredGiftCards(filtered);
  }, [amountFilter, statusFilter, giftCards]);

  return (
    <div className="container d-flex-column mt-5">
      <div className="row balance-header">
        <div className="col-2 p2 home-heading">
          <h4 style={{ color: "rgb(102 99 99)" }}>Gift Cards</h4>
        </div>
      </div>

      {/* Add Gift Card */}
      <div className="mb-3 d-flex-row">
        <div className="input-group">
          <input
            type="text"
            className="form-control mr-3 service-input margin-r wc-100"
            placeholder="Gift Card Code"
            value={giftCardCode}
            onChange={(e) => setGiftCardCode(e.target.value)}
          />
          <select
            className="form-control mr-3 service-input margin-r wc-100"
            value={usdValue}
            onChange={(e) => setUsdValue(e.target.value)}
          >
            <option value="">Select Value</option>
            {[
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 40, 50, 60, 70, 80, 90,
              100, 200, 300,
            ].map((value) => (
              <option key={value} value={value}>
                ${value}
              </option>
            ))}
          </select>
          <button className="btn btn-primary" onClick={handleAddGiftCard}>
            Add
          </button>
        </div>
      </div>

      {/* Gift Card Filters */}
      <div className="mb-3 d-flex-row">
        <div className="input-group">
          <select
            className="form-control mr-3"
            value={amountFilter}
            onChange={(e) => setAmountFilter(e.target.value)}
          >
            <option value="">Filter by Amount</option>
            {[
              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 40, 50, 60, 70, 80, 90,
              100, 200, 300,
            ].map((value) => (
              <option key={value} value={value}>
                ${value}
              </option>
            ))}
          </select>

          <select
            className="form-control mr-3"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="">Filter by Status</option>
            <option value="active">Active</option>
            <option value="redeemed">Redeemed</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      </div>

      {/* Display Filtered Gift Cards */}
      <div className="mt-4 d-flex-row">
        <h4 style={{ color: "rgb(102 99 99)" }}>Existing Gift Cards</h4>
        <table className="table discounts-table">
          <thead>
            <tr>
              <th>#</th>
              <th>GiftCode</th>
              <th>Value</th>
              <th>Used by</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredGiftCards.map((giftCard, index) => (
              <tr key={giftCard._id}>
                <td>{index + 1}</td>
                <td>{giftCard.code}</td>
                <td>${giftCard.amount}</td>
                <td>{giftCard.usedBy || "N/A"}</td>
                <td>{giftCard.status}</td>
                <td>
                  <button
                    className="btn btn-danger btn-sm remove-btn"
                    onClick={() => handleRemoveGiftCard(giftCard._id)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GiftCard;
