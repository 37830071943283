import React, { useEffect } from 'react';
import './Sidebar.css';
import { FaBattleNet, FaCodePullRequest, FaUsersGear, FaCircleDollarToSlot } from 'react-icons/fa6';
import logo from '../../Assets/Logo.PNG';
import {useNavigate, Link} from 'react-router-dom';
import { useHireContext } from '../../App.js';

const Sidebar = ({isActive, setIsActive}) => {
  const {setIsLoggedIn, name, email} = useHireContext();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    setIsLoggedIn(false);
    navigate('/login');
};
  return (
    <>
       <div className={`side-navbar  ${isActive ? 'active-nav' : ''} d-flex justify-content-between flex-wrap flex-column`} id="sidebar">
    <ul className="nav flex-column text-white w-100">
      <div className='logo-div'>
      <img src={logo} alt='' style={{width:'170px', height:'50px'}}/>
      </div>
      <Link to="/" className="nav-link">
        <FaCodePullRequest className='sidebar-icon'/>
        <span className="mx-2">Home</span>
      </Link>
      <Link to="/order" className="nav-link">
      <FaCodePullRequest className='sidebar-icon' />
      <span className="mx-2">Order</span>
    </Link>
    <Link to="/shoppingcarts" className="nav-link">
      <FaCodePullRequest className='sidebar-icon' />
      <span className="mx-2">Shopping Cart</span>
    </Link>
    <Link to="/history" className="nav-link">
      <FaUsersGear className='sidebar-icon'/>
      <span className="mx-2">Order History</span>
    </Link>
    <Link to="/finance" className="nav-link">
      <FaCircleDollarToSlot className='sidebar-icon'/>
      <span className="mx-2">Finance</span>
    </Link>
    <Link to="/settings" className="nav-link">
      <FaCircleDollarToSlot className='sidebar-icon'/>
      <span className="mx-2">Settings</span>
    </Link>
    </ul>
    <div>
    <div className='profile-bar'>
      <div>
      <svg xmlns="http://www.w3.org/2000/svg" fill="grey" width="50px" height="50px" viewBox="0 0 512 512"><path d="M256 73.825a182.175 182.175 0 1 0 182.18 182.18A182.177 182.177 0 0 0 256 73.825zm0 71.833a55.05 55.05 0 1 1-55.054 55.046A55.046 55.046 0 0 1 256 145.658zm.52 208.723h-80.852c0-54.255 29.522-73.573 48.885-90.906a65.68 65.68 0 0 0 62.885 0c19.363 17.333 48.885 36.651 48.885 90.906z" data-name="Profile"/></svg>
      </div>
      <div className='profile-info'>
        <span>{name}</span>
        <span style={{fontSize: '14px'}}>{email}</span>
      </div>
    </div>
    <Link to='/' onClick={handleLogout} className="btn btn-secondary logout-btn">
        Logout
      </Link>
      </div>
  </div>
    </>
  );
};

export default Sidebar;
