import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Login.css";
import { useHireContext } from "../../App.js";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OTPVerificationForm from "../OTPVerification/OTPVerification.js";

const Login = () => {
  const {
    setEmail,
    setBalance,
    setName,
    setIsLoggedIn,
    setApiStatus,
    setIsAdmin,
    setApiKey,
  } = useHireContext();
  const [password, setPassword] = useState("");
  const [email2, setEmail2] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [showVerificationForm, setShowVerificationForm] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!email2 || !password) {
      toast.error("Please fill in all required fields");
      return;
    }

    axios
      .post("https://Business.ozchest.com/login", {
        header: { "Content-Type": "application/json" },
        data: {
          email: email2,
          password: password,
        },
      })
      .then((response) => {
        if (response.data) {
          setSecretKey(response.data.secret);
          setShowVerificationForm(true);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error("Incorrect Email/Password");
        } else {
          toast.error(error);
        }
      });
  };

  const handleOTPVerification = (data2) => {
    // Send OTP and captcha data to the server for verification
    axios
      .post("https://Business.ozchest.com/verifyOtp", {
        data: {
          email: email2,
          secret: secretKey,
          otp: data2.otp,
        },
      })
      .then((response) => {
        setIsLoggedIn(true);
        sessionStorage.setItem("email", response.data.user.email);
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("name", response.data.user.name);
        sessionStorage.setItem("balance", response.data.user.balance);
        setBalance(response.data.user.balance);
        setName(response.data.user.name);
        setEmail(email2);
        setApiKey(response.data.user.apiKey);
        setApiStatus(response.data.user.apiStatus);
        if (response.data.user.isAdmin) {
          sessionStorage.setItem("isAdmin", true);
          setIsAdmin(true);
        } else {
          sessionStorage.removeItem("isAdmin");
          setIsAdmin(false);
          sessionStorage.setItem("apiKey", response.data.user.apiKey);
        }
        navigate("/");
      })
      .catch((error) => {
        // Handle OTP or captcha verification failure
        navigate("/login");
        toast.error("OTP verification failed");
      });
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>
          <i className="fa fa-user-circle"></i> Login
        </h2>
        {showVerificationForm ? (
          <OTPVerificationForm onSubmit={handleOTPVerification} />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="username">
                <i className="fa fa-user"></i> Email
              </label>
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                value={email2}
                onChange={(e) => setEmail2(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">
                <i className="fa fa-lock"></i> Password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-primary login-btn">
              <i className="fa fa-sign-in"></i> Log In
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
