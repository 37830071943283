import React, { useState, useEffect } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import './UsersInfo.css';

const UsersInfo = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    axios.post("https://Business.ozchest.com/getAllUsers",{isOzchest:false}).then((res) => {   
      if (res) {
        setUsers(res.data.users);
      } 
    });
  }, []);

  const openModal = (user) => {
    setSelectedUser(user);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setModalIsOpen(false);
  };

  const handleDeleteUser = (email) => {
    if (email) {
      const data = {
        user: email
      };
      axios.post("https://Business.ozchest.com/deleteUser", data).then((res) => {   
        if (res) {
          setUsers((prevUsers) => prevUsers.filter(user => user.email !== email));
          toast.success('Successfully deleted user');
        } 
      });
    }
  };

  const handleUpdateUser = () => {
    if (selectedUser) {
      const data = {
        user: selectedUser.email,
        balance: selectedUser.balance,
        apiStatus: selectedUser.apiStatus // Include API status in the data to be sent to the server
      };
      axios.post("https://Business.ozchest.com/updateUser", data).then((res) => {   
        if (res) {
          if (res?.status === 201) {
            toast.success('Api status updated successfully');
          } else {
            toast.success('Balance will be updated after verification');
          }
          closeModal();
        } 
      });
    }
  };

  return (
    <div className="container mt-5">
      <div className="row balance-header">
        <div className="col-2 p2 home-heading">
          <h4 style={{ color: 'rgb(102 99 99)' }}>Portal Users</h4>
        </div>
      </div>
      <div className="table-responsive mt-4" style={{ minHeight: '60vh' }}>
        <table className="table table-striped">
          <thead style={{ backgroundColor: '#141414' }}>
            <tr>
              <th style={{ border: 'none' }} scope="col">#</th>
              <th style={{ border: 'none' }}>Name</th>
              <th style={{ border: 'none' }}>Email</th>
              <th style={{ border: 'none' }}>Api Status</th>
              <th style={{ border: 'none' }}>Balance</th>
              <th style={{ border: 'none' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user, index) => (
              <tr key={user._id}>
                <td style={{ border: 'none' }}>{index + 1}</td>
                <td style={{ border: 'none' }}>{user.name}</td>
                <td style={{ border: 'none' }}>{user.email}</td>
                <td style={{ border: 'none' }}>{user.apiStatus}</td>
                <td style={{ border: 'none' }}>
                  {user.balance}
                </td>
                <td style={{ border: 'none' }}>
                  <button
                    className="btn btn-primary"
                    style={{ color: '#b9b08', marginRight: '5px' }}
                    onClick={() => handleDeleteUser(user.email)}
                  >
                    Delete
                  </button>
                  <button
                    className="btn btn-success ml-2"
                    style={{ color: '#b9b08' }}
                    onClick={() => openModal(user)}
                  >
                    Update
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Update Balance Modal"
        className="modal-content"
      >
        <h2 style={{ marginBottom: '20px' }}>Update User</h2>
        {selectedUser && (
          <div className='modal-balance-div'>
            <label style={{ marginBottom: '10px' }}>New Balance (USD):</label>
            <input
              type="number"
              value={selectedUser.balance}
              onChange={(e) =>
                setSelectedUser((prevUser) => ({ ...prevUser, balance: e.target.value }))
              }
              className="balance-input"
            />
            <label style={{ marginBottom: '10px' }}>API Status:</label>
            <select
              value={selectedUser.apiStatus}
              onChange={(e) =>
                setSelectedUser((prevUser) => ({ ...prevUser, apiStatus: e.target.value }))
              }
              className="api-status-select"
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
            <div className="modal-buttons">
              <button
                onClick={handleUpdateUser}
                className="btn btn-success"
              >
                Update
              </button>
              <button
                onClick={closeModal}
                className="btn btn-danger"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default UsersInfo;
