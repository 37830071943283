import React, { useState, useEffect } from 'react';
import './Discounts.css';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Discount = () => {
  const [discounts, setDiscounts] = useState([]);
  const [newDiscount, setNewDiscount] = useState('');
  const [newDiscountRate, setNewDiscountRate] = useState('');
  const [newDiscountType, setNewDiscountType] = useState('Percentage');

  useEffect(() => {
    axios.get("https://Business.ozchest.com/getAllDiscounts").then((res) => {   
      if (res) {
        setDiscounts(res.data.discounts);
      } 
    });
  }, []);

  const handleAddDiscount = () => {
    if (newDiscount.trim() !== '' && newDiscountRate.trim() !== '') {
      const data = {
        discount: newDiscount,
        rate: newDiscountRate,
        type: newDiscountType,
      };
      axios.post("https://Business.ozchest.com/addDiscount", data).then((res) => {
        if (res) {
          setDiscounts([...discounts, res.data]);
          setNewDiscount('');
          setNewDiscountRate('');
          setNewDiscountType('Percentage'); // Set default value after adding a new discount
          toast.success('Successfully Added New Discount');
        }
      }).catch((error) => {
        if (error.response.status === 400) {
          toast.error('Discount With Same Code Already Exists');
        } else {
          toast.error('Something went wrong!');
        }
      });
    } else {
      toast.error('Please enter all fields of the new discount');
    }
  };

  const handleRemoveDiscount = (discountToRemove) => {
    const data = {
        discount: discountToRemove,
    };
    axios.post("https://Business.ozchest.com/removeDiscount", data).then((res) => {   
      if (res) {
        const updatedServices = discounts.filter((discount) => discount.name !== discountToRemove);
        setDiscounts(updatedServices);
        toast.success('Successfully Removed Discount Code');
      } else {
        toast.error('Something went wrong!');
      }
    });
  };

  const handleSetRate = (discount) => {
    const data = {
      discount: discount._id,
      rate: discount.rate,
    };
    axios.post("https://Business.ozchest.com/setDiscountRate", data).then((res) => {
      if (res) {
        toast.success(`Rate for ${discount.name} updated successfully`);
      } else {
        toast.error('Something went wrong!');
      }
    });
  };

  const handleRateChange = (index, event) => {
    const newServices = [...discounts];
    newServices[index].rate = event.target.value;
    setDiscounts(newServices);
  };

  return (
    <div className="container d-flex-column mt-5">
      <div className="row balance-header">
      <div className="col-2 p2 home-heading">
      <h4 style={{ color: 'rgb(102 99 99)' }}>Discounts</h4>
      </div>
      </div>
      <div className="mb-3 d-flex-row">
        <div className="input-group">
          <input
            type="text"
            className="form-control mr-3 service-input margin-r wc-100"
            id="newDiscount"
            placeholder="Discount Code"
            value={newDiscount}
            onChange={(e) => setNewDiscount(e.target.value)}
          />
          <select
            className="form-control mr-3 service-input margin-r wc-100"
            id="newDiscountType"
            value={newDiscountType}
            onChange={(e) => setNewDiscountType(e.target.value)}
          >
            <option value="Percentage">Percentage</option>
            <option value="Fixed Cost">Fixed Cost</option>
          </select>
          <input
            type="number"
            min='0'
            className="form-control mr-3 margin-r"
            id="newDiscountRate"
            placeholder='Rate'
            value={newDiscountRate}
            onChange={(e) => setNewDiscountRate(e.target.value)}
          />
          <div className='d-type'>
          <span>{newDiscountType === 'Percentage' ? '%' : 'USD'}</span>
          </div>
          <button className="btn btn-primary" onClick={()=> handleAddDiscount()}>
            Add
          </button>
        </div>
      </div>
      <div className="mt-4 d-flex-row">
        <h4 style={{ color: 'rgb(102 99 99)' }}>Existing Discounts</h4>
        <table className="table discounts-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Code</th>
            <th>Type</th>
            <th>Rate</th>
            <th>Currency</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {discounts?.map((discount, index) => (
            <tr key={index}>
              <td>{index+1}</td>
              <td>{discount.name}</td>
              <td>{discount.type}</td>
              <td className='cstm-dflex'>
                <input
                  type="number"
                  className="form-control"
                  min='0'
                  placeholder="Service Rate"
                  style={{width: '100px'}}
                  value={discount.rate || 0}
                  onChange={(e) => handleRateChange(index, e)}
                />
              </td>
              <td>{discount.type === 'Percentage' ? '%' : 'USD'}</td>
              <td>
                <button
                  className="btn btn-danger btn-sm remove-btn"
                  onClick={() => handleRemoveDiscount(discount.name)}
                >
                  Remove
                </button>
                <button
                className="btn btn-primary btn-sm ml-2"
                onClick={() => handleSetRate(discount)}
              >
                Set Rate
              </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default Discount;
