import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Form, Button, Dropdown, Table, Pagination } from 'react-bootstrap';
import './UpdatePricing.css'
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MAX_PAGES_DISPLAYED = 5;
const PricingComponent = () => {
  const [currency, setCurrency] = useState('');
  const [amount, setAmount] = useState(0);
  const [isPercentage, setIsPercentage] = useState(false);
  const [isOzchest, setIsOzchest] = useState(true);
  const [currentTab, setCurrentTab] = useState('currency');
  const [products, setProducts] = useState([]);
  const [unitLoading, setUnitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [unitRate, setUnitRate] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (currentTab === 'unit') {
      setUnitLoading(true);
      const data = {
        isOzchest: isOzchest
      }
      axios.post("https://Business.ozchest.com/getAllProducts", data).then((res) => {   
      if (res) {
        setProducts(res.data.products);
      } 
      setUnitLoading(false);
    });
    }
  }, [currentTab]);

  const handleCurrencyRateSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      amount: amount,
      isPercentage: isPercentage,
      currency: currency,
      isOzchest: isOzchest
    };
    axios.post("https://Business.ozchest.com/setCurrencyRate", data).then((res) => {
      if (res) {
        toast.success('Successfully Added Currency Rate');
      }
      setLoading(false);
    }).catch((error) => {
        setLoading(false);
        toast.error('Something went wrong!');
    });
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleToggleChange = () => {
    setIsPercentage(!isPercentage);
  };

  const handleProductPriceChange = (newPrice) => {
    setUnitRate(newPrice);
  };

  const filterProducts = () => {
    if (!searchQuery) {
      // If search query is empty, return all products
      return products;
    }

    // Filter products based on search query
    return products.filter((product) =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 20;
  const totalProducts = filterProducts()?.length;
  const totalPages = Math.ceil(totalProducts / productsPerPage);

  const renderPaginationItems = () => {
    if (totalPages <= MAX_PAGES_DISPLAYED) {
      return Array.from({ length: totalPages }).map((_, index) => (
        <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => paginate(index + 1)}>
          {index + 1}
        </Pagination.Item>
      ));
    }

    const pagesToDisplay = [
      1,
      2,
      '...',
      totalPages - 1,
      totalPages,
    ];

    return pagesToDisplay.map((page, index) => (
      <Pagination.Item
        key={index}
        active={page === currentPage}
        disabled={page === '...'}
        onClick={() => (typeof page === 'number' ? paginate(page) : null)}
      >
        {page}
      </Pagination.Item>
    ));
  };
  
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  let currentProducts = filterProducts().slice(indexOfFirstProduct, indexOfLastProduct);

  const handleUpdateClick = (productId) => {
    if (selectedItems.length === 0) {
      toast.error('Please select product');
      return;
    }
    if (unitRate !== undefined) {
      setLoading(true);
      const data = {
        amount: unitRate,
        isPercentage: isPercentage,
        productIds: selectedItems,
        isOzchest: isOzchest
      };
      axios.post("https://Business.ozchest.com/setUnitRate", data).then((res) => {
        if (res) {
          setProducts((prevProducts) => {
            const updatedProductIds = res.data.products?.map(updatedProduct => updatedProduct._id);
          
            const newProducts = prevProducts.map(product => {
              if (updatedProductIds.includes(product._id)) {
                return res.data.products.find(updatedProduct => updatedProduct._id === product._id);
              } else {
                return product;
              }
            });
          
            return newProducts;
          });
        }
        setSelectedItems([]);
        currentProducts = filterProducts().slice(indexOfFirstProduct, indexOfLastProduct);
        setLoading(false);
        toast.success('Successfully Update Unit Rate');
      }).catch((error) => {
          setLoading(false);
          toast.error('Something went wrong!');
      });
    }
  };

  const handleCheckboxChange = (productId) => {
    const updatedSelectedItems = [...selectedItems];
    const index = updatedSelectedItems.indexOf(productId);

    if (index !== -1) {
      updatedSelectedItems.splice(index, 1);
    } else {
      updatedSelectedItems.push(productId);
    }

    setSelectedItems(updatedSelectedItems);
  };

  // Function to handle checkbox change for "Select All"
  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);

    if (!selectAll) {
      // If "Select All" is checked, select all items
      const allProductIds = filterProducts()?.map((product) => product.productId);
      setSelectedItems(allProductIds);
    } else {
      // If "Select All" is unchecked, clear all selections
      setSelectedItems([]);
    }
  };

  const handleRateTypeChange = (selectedValue) => {
    setIsPercentage(selectedValue === 'percentage');
  };

  return (
    loading ? <div className="cart-spinner-container">
            <div className="spinner-border cstm-spinner" role="status">
              <span className="sr-only"></span>
            </div>
          </div> :
      <Tabs defaultActiveKey={currentTab} id="rate-tabs" onSelect={(key) => setCurrentTab(key)}>

        <Tab eventKey="currency" title="Currency Rate">
        <div className='rate-calculator-container'>
        <Form onSubmit={handleCurrencyRateSubmit}>
        <Form.Group controlId="currency">
        <div className='products-form'>
          <Form.Label>Set Currency Rate</Form.Label>
        </div>
          <div className="currency-input-group">
            <Dropdown onSelect={(selectedCurrency) => setCurrency(selectedCurrency)}>
              <Dropdown.Toggle variant="success" id="currencyDropdown">
                {currency || 'Select Currency'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="USD">USD</Dropdown.Item>
                <Dropdown.Item eventKey="EUR">EUR</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Form.Control
              min={isPercentage ? 0: ""}
              className="rate-input"
              type="number"
              placeholder="Enter amount"
              value={amount}
              onChange={handleAmountChange}
            />
          </div>
        </Form.Group>

        <Form.Check type="switch" id="percentageSwitch" label="Percentage" checked={isPercentage} onChange={handleToggleChange} />

        <Button variant="primary" type="submit" className="rate-btn">
          Set Rate
        </Button>
      </Form>
          </div>
        </Tab>

        <Tab eventKey="unit" title="Unit Rate">
        <div className="rate-calculator-container">
      <Form>
        <div className='products-form'>
        <div className="search-container">
        <Form.Control
          type="text"
          className="rate-input2"
          placeholder="Search by name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className="rate-dropdown-container">
        <Form.Select
          className="rate-dropdown"
          onChange={(e) => handleRateTypeChange(e.target.value)}
        >
          <option value="fixed">Fixed(USD)</option>
          <option value="percentage">Percentage</option>
        </Form.Select>
        <Form.Control
          min={isPercentage ? 0 : ""}
          className="rate-input2"
          type="number"
          placeholder="Enter amount"
          onChange={(e) => handleProductPriceChange(e.target.value)}
        />
        </div>
        <Button variant="primary" className='update-btn' onClick={() => handleUpdateClick()}>
          Update
        </Button>
      </div>
        </div>
        {unitLoading ? <div className="cart-spinner-container">
            <div className="spinner-border cstm-spinner" role="status">
              <span className="sr-only"></span>
            </div>
          </div> :
        <Table  responsive borderless>
          <thead>
            <tr>
              <th><Form.Check
              className='product-checkbox'
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAllChange}
              /></th>
              <th>Name</th>
              <th>Min Price</th>
              <th>Max Price</th>
              <th>Updated Min</th>
              <th>Updated Max</th>
              <th>Currency</th>
            </tr>
          </thead>
          <tbody>
            {currentProducts.map((product) => (
              <tr key={product._id}>
                <td>
                    <Form.Check
                      className='product-checkbox'
                      type="checkbox"
                      checked={selectedItems.includes(product.productId)}
                      onChange={() => handleCheckboxChange(product.productId)}
                    />
                  </td>
                <td>{product.name}</td>
                <td>{product.originalPrice.min}</td>
                <td>{product.originalPrice.max}</td>
                <td>{product.price.min}</td>
                <td>{product.price.max}</td>
                <td>{product.price.currencyCode}</td>
              </tr>
            ))}
          </tbody>
        </Table>
}
        <Pagination>
              {renderPaginationItems()}
            </Pagination>
      </Form>
    </div>
        </Tab>
      </Tabs>
  );
};

export default PricingComponent;
