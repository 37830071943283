import React, {useState, useEffect} from 'react';
import './History.css';
import axios from "axios";
import { useHireContext } from '../../App.js';

const History = () => {
  const [orders, setOrders] = useState([]);
  const {email, balance} = useHireContext();

  useEffect(() => {
    const data = {
        user: email
    }
    axios.post("https://Business.ozchest.com/getOrders", data).then((res) => {   
      if (res) {
        setOrders(res.data.orders);
      } 
    });
  }, []);

  return (
    <div className="container history-container mt-5" >
      <div className="row balance-header">
        <div className="col-2 p2 home-heading">
          <h4>History</h4>
        </div>
        <div className="col">
          <div className='p-2 balance-div'>
              <div className='balance-box'> 
                <h5>Balance: ${balance?.toFixed(3)}</h5>
                </div>
          </div>
        </div>
      </div>
      <div className="table-responsive mt-4">
        <table className="table">
          <thead className="fw-bold" style={{ backgroundColor: '#141414' }}>
            <tr>
              <th style={{ border: 'none' }} scope="col">#</th>
              <th style={{ border: 'none' }} scope="col">Client</th>
              <th style={{ border: 'none' }} scope="col">Brand</th>
              <th style={{ border: 'none' }} scope="col">Total Price</th>
              <th style={{ border: 'none' }} scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {orders?.map((order, index) => (
              <tr key={order._id}>
                <td style={{ border: 'none', textAlign: 'center' }}>{index + 1}</td>
                <td style={{ border: 'none', textAlign: 'center' }}>{order.user}</td>
                <td style={{ border: 'none', textAlign: 'center' }}>{order.brand}</td>
                <td style={{ border: 'none', textAlign: 'center' }}>${order.price?.toFixed(3)}</td>
                <td style={{ border: 'none', textAlign: 'center' }} className={`project-status ${order.status.toLowerCase()}`}>
                    {order.status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default History;
